<script>
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import DrawerIconHeader from '/~/components/drawer/components/drawer-icon-header.vue'

export default {
  name: 'drawer-help-confirmation',
  components: {
    DrawerIconHeader,
    BaseButton,
    BaseAsidePage,
  },
}
</script>

<template>
  <base-aside-page no-padding>
    <template #iconHeader>
      <drawer-icon-header title="Message successful" icon="check-filled" />
    </template>
    <div class="flex h-full flex-col justify-between bg-white px-5 pb-[35px]">
      <p class="pt-5 text-center text-lg">
        Your message has been sent to the support team and they will respond as
        soon as possible.
      </p>
      <base-button type="primary" @click="$emit('done')">Got it</base-button>
    </div>
  </base-aside-page>
</template>
